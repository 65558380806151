<template>
  <div id="download">
    <section class="py-9 bg-parallax" style="background-image: url(/assets/img/background/background-img-1.jpg);">
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">【家長請進】練習單</h1>
            <p class="text-white font-size-16">根據親子天下調查，有高達95%認為應該從小培養孩子的理財觀念，但是只有21%的家長可以回答孩子的理財問題，如果你想培養孩子的金融素養，歡迎你下載分齡的理財練習單，我們陪孩子 一起學理財。</p>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item ">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">家長請進</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="py-8 py-md-10">
      <div class="container">
        <div class="section-title justify-content-center mb-8">
          <span class="shape shape-left bg-info"></span>
          <h2 class="text-danger">Level {{ sheetDetail.level }}</h2>
          <span class="shape shape-right bg-info"></span>
        </div>

        <div class="row justify-content-center">
          <div v-for="(img, index) in displayedImages" :key="index" class="col-md-6 col-lg-4 mb-md-4">
            <div class="card">
              <a href="javascript:void(0)" @click="clickedImage = img.img" data-toggle="modal" data-target="#modal-products" class="download__img-container position-relative">
                <img class="download__img-image card-img-top" :src="img.img" alt="Card image">
                <div class="card-img-overlay">
                  <span class="badge badge-warning badge-rounded-circle"><i class="fa fa-eye " aria-hidden="true"></i></span>
                </div>
              </a>
              <div class="card-body border-top-5 px-3 rounded-bottom border-warning">
                <div class="d-flex justify-content-center align-items-baseline p-0 border-0">
                  <a @click="fileDownloader(img.filePath, 'jpg', img.fileName)" href="javascript:void(0)" class="btn btn-white text-uppercase mb-1  mr-5 btn-hover-warning">
                    <i class="fa fa-cloud-download mr-2" aria-hidden="true"></i>下載
                  </a>
                  <a @click="printImage(img.img)" href="javascript:void(0)" class="btn btn-white text-uppercase mb-1 btn-hover-warning">
                    <i class="fa fa-print mr-2" aria-hidden="true"></i>列印
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="mt-6">
      <!-- ====================================
        ——— PAGINATION
        ===================================== -->
        <AppPagination
          :currentPage="currentPage"
          :totalPages="totalPages"
          @pageChange="page => currentPage = page"
        />
      </div>
    </section>

    <!-- Modal Products -->
    <ImageModal :image="clickedImage" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import tokenGenerator from '@/tokenGenerator.js'
import printImage from '@/printImage.js'
import fileDownloader from '@/fileDownloader.js'
import ImageModal from '@/components/ImageModal.vue'
import AppPagination from '@/components/AppPagination.vue'
import richkidConfig from '@/assets/json/richkid.config.json'
export default {
  name: 'Download',
  components: { ImageModal, AppPagination },
  data () {
    return {
      sheetDetail: {
        level: 0,
        img: []
      },
      clickedImage: '',
      currentPage: 1
    }
  },
  computed: {
    printImage () {
      return printImage
    },
    totalPages () {
      return Math.ceil(this.sheetDetail.img.length / 6)
    },
    displayedImages () {
      return this.sheetDetail.img.slice((this.currentPage - 1) * 6, this.currentPage * 6)
    },
    richkidGradeData () {
      return richkidConfig.data.grade
    },
    fileDownloader () {
      return fileDownloader
    }
  },
  created () {
    const vm = this
    vm.getSheetDetail()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getSheetDetail () {
      const { id } = this.$route.params
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/sheet/detail`

      vm.axios.post(api, { id, token: tokenGenerator() }).then(res => {
        console.log(res)
        const item = res.data.content
        const gradeName = this.richkidGradeData[item.grade].title
        item.img.forEach(imgItem => {
          imgItem.filePath = imgItem.img.replace('https://gcs.moneybar.com.tw/', '')
          imgItem.fileName = `${dayjs().format('YYYY-MM-DD')}_圖片_${gradeName}_level${item.level}.jpg`
        })
        vm.sheetDetail = item
      })
    },
    downloadImage (link) {
      alert('請點選右鍵以儲存圖片')
      const a = document.createElement('a')
      a.href = link
      a.target = '_blank'
      a.click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/kidz.css';
.download__img {
  &-container {
    height: 254.7px;
  }
  &-image {
    height: 100%;
    object-fit: cover;
  }
}
</style>
