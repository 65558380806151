export default function (imageLink) {
  var img = new Image()
  img.src = imageLink
  img.onload = () => {
    setTimeout(() => {
      const popup = window.open()
      popup.document.write(`<html style="margin: 0; padding: 0"><head></head><body style="margin: 0; padding: 0; width: 100vw; height: 100vh;"><img src=${imageLink} style="width:100%; height: 100%" /></body>`)
      popup.print()
      popup.close()
    }, 1000)
  }
}
