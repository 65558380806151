<template>
  <section class="py-5">
    <div class="container">
      <nav aria-label="Page navigation example">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center align-items-center">
            <li class="page-item"><button :disabled="currentPage === 1" @click="$emit('pageChange', currentPage - 1)" class="page-link">
              <i class="fa fa-arrow-left mr-1" aria-hidden="true"></i>
              Prev
            </button>
            </li>
            <li v-for="page in totalPages" :key="page" class="page-item">
              <a @click="setPage(page)" :class="currentPage === page ? 'text-danger' : ''" class="page-link" href="javascript:void(0)">{{ page }}</a>
            </li>
            <li class="page-item"><button :disabled="currentPage === totalPages" @click="$emit('pageChange', currentPage + 1)" class="page-link">
              Next <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
            </button>
            </li>
          </ul>
        </nav>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    }
  },
  methods: {
    setPage (page) {
      if (page === this.currentPage) return null
      this.$emit('pageChange', page)
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

button:disabled {
  background: gray !important;
}
</style>
